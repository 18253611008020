"use client";

import { styled } from "styled-components";
import { DetailText, LeadText } from "@gnist/design-system";
import { InternalErrorIcon } from "../components/icons";
import { tokens } from "@gnist/themes/tokens.css.js";

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 40px;

  justify-content: center;
  align-items: center;

  width: 100%;
  height: 100%;
`;

const TextContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: ${tokens.spacing.xxs};

  align-items: center;
`;

const StyledDescription = styled(DetailText)`
  text-align: center;
`;

export default function ErrorMessage({
  error,
  reset,
}: {
  error: Error & { digest?: string };
  reset: () => void;
}) {
  return (
    <Wrapper>
      <InternalErrorIcon />
      <TextContainer>
        <LeadText>Det har oppstått en feil hos oss!</LeadText>
        <StyledDescription>
          Vi jobber med saken, så prøv igjen om en liten stund.
        </StyledDescription>
      </TextContainer>
    </Wrapper>
  );
}
